<template>
  <div class="row csn-user-docs csn-user-account-section" id="user-account">
    <div class="col-md-12">
      <div class="container">
        <section>
          <h1>{{ t('title') }}</h1>
          <p>{{ t('description') }}</p>
        </section>

        <UserDocumentForm />

        <section v-if="IS_DOCUMENT_PENDING">
          <Loader />
        </section>

        <section>
          <Message :type="msgType" :message="DOCUMENT_MESSAGE" :hasTop="true" />
        </section>

        <section v-if="rendersTable">
          <h2 class="csn-user-docs-table-title">{{ t('my_documents') }}</h2>
          <section
            v-if="isMobileScreen"
            class="csn-user-docs-mobile-table-container"
          >
            <table
              v-for="({
                filename,
                created,
                type,
                expiryDate,
                issueDate,
                status,
              },
              id) in DOCUMENT_DICTIONARY"
              :key="id"
              class="csn-user-docs-mobile-table"
            >
              <tr>
                <th>{{ t('file_name') }}</th>
                <td>
                  {{ filename }}
                </td>
              </tr>
              <tr>
                <th>{{ t('upload_date') }}</th>
                <td>
                  {{ convertDateTime(created) }}
                </td>
              </tr>
              <tr>
                <th>{{ t('type') }}</th>
                <td>{{ type }}</td>
              </tr>
              <tr>
                <th>{{ t('expiry_date') }}</th>
                <td>
                  {{ convertDateTime(expiryDate) || '-' }}
                </td>
              </tr>
              <tr>
                <th>{{ t('issue_date') }}</th>
                <td>
                  {{ convertDateTime(issueDate) || '-' }}
                </td>
              </tr>
              <tr>
                <th>{{ t('status') }}</th>
                <td class="csn-user-docs-mobile-table-status-value">
                  <span>
                    {{ status }}
                  </span>
                </td>
              </tr>
            </table>
          </section>

          <table v-else>
            <tr>
              <th>{{ t('file_name') }}</th>
              <th>{{ t('upload_date') }}</th>
              <th>{{ t('type') }}</th>
              <th>{{ t('expiry_date') }}</th>
              <th>{{ t('issue_date') }}</th>
              <th>{{ t('status') }}</th>
            </tr>

            <tr
              v-for="({
                filename,
                created,
                type,
                expiryDate,
                issueDate,
                status,
              },
              id) in DOCUMENT_DICTIONARY"
              :key="id"
            >
              <td class="csn-user-docs-td" :title="filename">
                {{ filename }}
              </td>
              <td class="csn-user-docs-td">
                {{ convertDateTime(created) }}
              </td>
              <td class="csn-user-docs-td">{{ type }}</td>
              <td class="csn-user-docs-td">
                {{ convertDateTime(expiryDate) || '-' }}
              </td>
              <td class="csn-user-docs-td">
                {{ convertDateTime(issueDate) || '-' }}
              </td>
              <td>{{ status }}</td>
            </tr>
          </table>
        </section>

        <section v-if="rendersEmptyListNote">
          <p class="csn-user-date-empty-list">{{ t('no_documents') }}</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  USER_DOCUMENTS,
  Module,
  DOCUMENT_DICTIONARY,
  IS_DOCUMENT_PENDING,
  DateFormat,
  DOCUMENT_MESSAGE,
  MessageTypeEnum,
  IS_MOBILE_SCREEN,
} from '@/constants'
import { isEmptyOrNil, formatDate, isEmpty } from '@/helpers'
import { dest } from '@/helpers'

export default {
  name: USER_DOCUMENTS,
  components: {
    UserDocumentForm: () => import('./components/UserDocumentForm'),
    Loader: () => import('@/components/Loader'),
    Message: () => import('@/components/atoms/Message'),
  },
  computed: {
    t() {
      return this.$createComponentTranslator(USER_DOCUMENTS)
    },
    ...mapState(Module.DOCUMENT, {
      DOCUMENT_DICTIONARY,
      IS_DOCUMENT_PENDING,
      DOCUMENT_MESSAGE,
    }),
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    rendersEmptyListNote() {
      return (
        !this.IS_DOCUMENT_PENDING &&
        isEmpty(this.DOCUMENT_MESSAGE) &&
        isEmpty(this.DOCUMENT_DICTIONARY)
      )
    },
    rendersTable() {
      return !this.IS_DOCUMENT_PENDING && !isEmpty(this.DOCUMENT_DICTIONARY)
    },
    msgType: () => MessageTypeEnum.FAILURE,
  },
  methods: {
    convertDateTime(date) {
      if (isEmptyOrNil(date)) {
        return
      }

      return formatDate({ format: DateFormat.DD_MM_YY, date })
    },
  },
}
</script>
