var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row csn-user-docs csn-user-account-section",attrs:{"id":"user-account"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"container"},[_c('section',[_c('h1',[_vm._v(_vm._s(_vm.t('title')))]),_c('p',[_vm._v(_vm._s(_vm.t('description')))])]),_c('UserDocumentForm'),(_vm.IS_DOCUMENT_PENDING)?_c('section',[_c('Loader')],1):_vm._e(),_c('section',[_c('Message',{attrs:{"type":_vm.msgType,"message":_vm.DOCUMENT_MESSAGE,"hasTop":true}})],1),(_vm.rendersTable)?_c('section',[_c('h2',{staticClass:"csn-user-docs-table-title"},[_vm._v(_vm._s(_vm.t('my_documents')))]),(_vm.isMobileScreen)?_c('section',{staticClass:"csn-user-docs-mobile-table-container"},_vm._l((_vm.DOCUMENT_DICTIONARY),function(ref,id){
            var filename = ref.filename;
            var created = ref.created;
            var type = ref.type;
            var expiryDate = ref.expiryDate;
            var issueDate = ref.issueDate;
            var status = ref.status;
return _c('table',{key:id,staticClass:"csn-user-docs-mobile-table"},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('file_name')))]),_c('td',[_vm._v(" "+_vm._s(filename)+" ")])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('upload_date')))]),_c('td',[_vm._v(" "+_vm._s(_vm.convertDateTime(created))+" ")])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('type')))]),_c('td',[_vm._v(_vm._s(type))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('expiry_date')))]),_c('td',[_vm._v(" "+_vm._s(_vm.convertDateTime(expiryDate) || '-')+" ")])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('issue_date')))]),_c('td',[_vm._v(" "+_vm._s(_vm.convertDateTime(issueDate) || '-')+" ")])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('status')))]),_c('td',{staticClass:"csn-user-docs-mobile-table-status-value"},[_c('span',[_vm._v(" "+_vm._s(status)+" ")])])])])}),0):_c('table',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('file_name')))]),_c('th',[_vm._v(_vm._s(_vm.t('upload_date')))]),_c('th',[_vm._v(_vm._s(_vm.t('type')))]),_c('th',[_vm._v(_vm._s(_vm.t('expiry_date')))]),_c('th',[_vm._v(_vm._s(_vm.t('issue_date')))]),_c('th',[_vm._v(_vm._s(_vm.t('status')))])]),_vm._l((_vm.DOCUMENT_DICTIONARY),function(ref,id){
            var filename = ref.filename;
            var created = ref.created;
            var type = ref.type;
            var expiryDate = ref.expiryDate;
            var issueDate = ref.issueDate;
            var status = ref.status;
return _c('tr',{key:id},[_c('td',{staticClass:"csn-user-docs-td",attrs:{"title":filename}},[_vm._v(" "+_vm._s(filename)+" ")]),_c('td',{staticClass:"csn-user-docs-td"},[_vm._v(" "+_vm._s(_vm.convertDateTime(created))+" ")]),_c('td',{staticClass:"csn-user-docs-td"},[_vm._v(_vm._s(type))]),_c('td',{staticClass:"csn-user-docs-td"},[_vm._v(" "+_vm._s(_vm.convertDateTime(expiryDate) || '-')+" ")]),_c('td',{staticClass:"csn-user-docs-td"},[_vm._v(" "+_vm._s(_vm.convertDateTime(issueDate) || '-')+" ")]),_c('td',[_vm._v(_vm._s(status))])])})],2)]):_vm._e(),(_vm.rendersEmptyListNote)?_c('section',[_c('p',{staticClass:"csn-user-date-empty-list"},[_vm._v(_vm._s(_vm.t('no_documents')))])]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }